/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
	switch (action.type) {
		case 'USER_LOGIN':
			return {
				...state,
				user: action.payload.userObject,
				userToken: action.payload.userToken || state.userToken,
			}
		case 'USER_LOGOUT':
			return {
				...state,
				user: false,
				userToken: false,
			}
		case 'USER_BUSY':
			return {
				...state,
				userBusy: action.payload,
			}
		default:
			return state
	}
}
