import React, { useContext, useEffect } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { Route, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import SeForgot from '../../components/shared/SeForgot'
import SeLogin from '../../components/shared/SeLogin'
import SeRestore from '../../components/shared/SeRestore'
import SeSignup from '../../components/shared/SeSignup'
import { UserContext } from '../../context/shared/UserContext'

export function Access({ setLoadingBar, setAlert }) {
	const { recoverCode } = useParams() || false
	const { user, userBusy } = useContext(UserContext)
	return (
		<>
			<Route path={['/']} exact>
				{!userBusy && !user && (
					<>
						<Row>
							<Col md={6} className="text-center pt-4">
								<SeLogin setLoadingBar={setLoadingBar} setAlert={setAlert}></SeLogin>
								<Row>
									<Col md={12} className="text-center pt-4">
										<Link to="/recuperar-cuenta">
											<p>Olvidaste tu contraseña? Recupera tu cuenta aquí</p>
										</Link>
									</Col>
								</Row>
							</Col>
							<Col md={6} className="text-center pt-4">
								<SeSignup setLoadingBar={setLoadingBar} setAlert={setAlert}></SeSignup>
							</Col>
						</Row>
						{/* <hr /> */}
					</>
				)}
			</Route>
			<Route path={['/recuperar-cuenta']} exact>
				<SeForgot setLoadingBar={setLoadingBar} setAlert={setAlert}></SeForgot>
			</Route>
			<Route path={['/recuperar-cuenta/:recoverCode']} exact>
				{recoverCode && (
					<>
						<SeRestore setLoadingBar={setLoadingBar} setAlert={setAlert} recoverCode={recoverCode}></SeRestore>
					</>
				)}
			</Route>
		</>
	)
}
