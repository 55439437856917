import './PaymentsPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Badge from 'react-bootstrap/esm/Badge'
import Table from 'react-bootstrap/esm/Table'
import Alert from 'react-bootstrap/esm/Alert'
import Row from 'react-bootstrap/esm/Row'

import SeMenu from '../components/SeMenu'
import SeHeroUser from '../components/SeHeroUser'
import Button from 'react-bootstrap/esm/Button'

import 'react-circular-progressbar/dist/styles.css'

import { GiMoneyStack } from 'react-icons/gi'
import { IconContext } from 'react-icons/lib'
import { RiSecurePaymentLine } from 'react-icons/ri'
import { useContext } from 'react'
import { UserContext } from '../context/shared/UserContext'
import { AnchorsContext } from '../context/shared/AnchorsContext'

export default function PaymentsPage() {
	const { anchors, currentAnchor, payments } = useContext(AnchorsContext)
	const { user } = useContext(UserContext)

	const isPageReady = anchors !== false && anchors?.length > 0 && currentAnchor !== false

	return (
		<Container className="se-container-page">
			<SeHeroUser />
			<section className="se-section se-section-payments">
				<SeMenu />
				<div className="wrapper">
					<Row className="pt-3">
						<Col md={1} style={{ padding: '5px 30px' }}>
							<IconContext.Provider value={{ color: 'gray', size: '40px' }}>
								<div>
									<GiMoneyStack />
								</div>
							</IconContext.Provider>
						</Col>
						{isPageReady && (
							<Col md={11}>
								<h4 className="mb-0">{anchors[currentAnchor]._meta.product.name}</h4>
								<p>
									Estos son todos los pagos relacionados a <u>{user.userEmail}</u>
								</p>
							</Col>
						)}
					</Row>
					{/* content */}
					{payments?.length > 0 ? (
						<Row>
							<Col md={12} className="pt-3">
								<Table striped bordered hover>
									<thead>
										<tr>
											<th className="col-md-2">Identificador</th>
											<th className="col-md-7">Concepto(s)</th>
											<th className="col-md-2">Pago</th>
										</tr>
									</thead>
									<tbody>
										{payments.map((payment, index) => (
											<tr key={index}>
												<td>
													{payment.folio}
													{payment.status === 'Pagado' ? (
														<Badge variant="success" style={{ backgroundColor: '#198754' }}>
															{payment.status}
														</Badge>
													) : (
														<Badge variant="success" style={{ backgroundColor: '#ffc107' }}>
															{payment.status}
														</Badge>
													)}
												</td>
												<td>
													<ul style={{ listStyle: 'square' }}>
														{payment.concepts.map((concept, index) => (
															<li key={index}>{concept}</li>
														))}
														<small style={{ color: '#666' }}>
															{payment.start} - {payment.end}
														</small>
													</ul>
												</td>
												<td>
													{payment.status !== 'Pagado' && (
														<a href={payment.paymentURL}>
															<Button
																size="sm"
																variant="success"
																style={{
																	backgroundColor: '#87c053',
																	color: '#000',
																	borderColor: 'transparent',
																	display: 'block',
																	marginBottom: '5px',
																}}
															>
																<RiSecurePaymentLine style={{ position: 'relative', bottom: '1px' }} />
																&nbsp;Pagar
															</Button>
														</a>
													)}
													<a href={payment.invoiceURL}>
														<Button
															size="sm"
															variant="info"
															style={{
																backgroundColor: '#0dcaf0',
																color: '#000',
																borderColor: 'transparent',
																display: 'block',
																marginBottom: '5px',
															}}
														>
															<RiSecurePaymentLine style={{ position: 'relative', bottom: '1px' }} />
															&nbsp;Descargar recibo
														</Button>
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Col>
						</Row>
					) : (
						<Row className="pt-3">
							<Col md={12} className="text-center">
								<Alert variant="warning">Lo sentimos, no encontramos ningún pago para mostrar. Regresa más tarde</Alert>
							</Col>
						</Row>
					)}
					{/* content */}
				</div>
			</section>
		</Container>
	)
}
