import './SeHeroUser.css'

import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Button from 'react-bootstrap/esm/Button'
import { Link, useLocation } from 'react-router-dom'
import { BiMessageAltError } from 'react-icons/bi'
import { TiArrowBack } from 'react-icons/ti'
import { useContext } from 'react'
import { UserContext } from '../context/shared/UserContext'
import { AnchorsContext } from '../context/shared/AnchorsContext'
import SeAnchorSelector from './SeAnchorSelector'

export default function SeHeroUser() {
	const { user } = useContext(UserContext)
	const { anchors, currentAnchor } = useContext(AnchorsContext)
	const location = useLocation()

	if (!user) {
		return null
	}
	const userPhotoComponent = () => {
		if (user.userPhoto) {
			return <div className="se-user-photo" style={{ backgroundImage: 'url(' + user.userPhoto + ')' }}></div>
		} else {
			return (
				<div className="se-user-photo">
					<p>Sin fotografía</p>
				</div>
			)
		}
	}

	const isAnchorReady = anchors && anchors.length > 0 && currentAnchor !== false

	return (
		<Row>
			<Col md={12} className="se-user-container text-center">
				<div className="image-container">
					<Link to="/informacion-basica">{userPhotoComponent()}</Link>
				</div>

				<p>
					<strong>{user.userName}</strong>
				</p>
				<p>{user.userEmail}</p>
			</Col>
			{location.pathname !== '/historial-de-formacion' && (
				<Col md={12} className="text-center">
					{isAnchorReady && anchors?.length > 1 ? (
						<SeAnchorSelector />
					) : (
						<h4 className="text-center pt-2 pb-3">{anchors[currentAnchor]?._meta.name}</h4>
					)}
					{isAnchorReady && location.pathname !== '/' && (
						<>
							<Link to="/">
								<Button className="mb-3" style={{ width: 'auto', backgroundColor: 'var(--se-dia-azul)', border: '0px' }}>
									<TiArrowBack style={{ position: 'relative', bottom: '2px' }} /> Regresar al menú principal
								</Button>
							</Link>
						</>
					)}
				</Col>
			)}

			{anchors?.length === 0 && (
				<Col md={{ span: 10, offset: 1 }} className="se-anchor-container text-center">
					<div className="card">
						<p className="text-center">
							<BiMessageAltError style={{ fontSize: '50px' }} />
						</p>
						<p className="mb-1">
							Hola <strong>{user.userName}</strong>, por el momento tu cuenta no está inscrita en ningún plan de estudios
						</p>
						<p>
							Te recomendamos revisar nuestra oferta educativa o comunicarte con nuestro equipo administrativo para reportar algún error
						</p>
					</div>
				</Col>
			)}
		</Row>
	)
}

// <Col md={{ span: 6, offset: 3 }} className="se-anchor-container text-center">
// 	<Form.Group controlId="formBasicUserCountry">
// 		<Form.Label>Selecciona un Plan de Estudios</Form.Label>
// 		<Form.Control
// 			as="select"
// 			placeholder="Selecciona un Plan de Estudios"
// 			value={currentAnchor}
// 			onChange={(e) => setCurrentAnchor(e.target.value)}
// 		>
// 			{anchors.map((anchor, index) => (
// 				<option key={index} value={index}>
// 					{anchor._meta.name}
// 				</option>
// 			))}
// 		</Form.Control>
// 	</Form.Group>
// </Col>
