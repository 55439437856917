import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Form from 'react-bootstrap/esm/Form'
import { useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { SiMinutemailer } from 'react-icons/si'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { platformBearerToken, platformURL } from '../../classes/Environment'
import { useContext } from 'react'
import { UserContext } from '../../context/shared/UserContext'

export default function SeRestore({ setLoadingBar, setAlert, recoverCode }) {
	const [userEmail, setUserEmail] = useState('')
	const [userPassword, setUserPassword] = useState('test')
	const [userPasswordConfirm, setUserPasswordConfirm] = useState('test')

	const [lastError, setLastError] = useState(false)
	const { addToast } = useToasts()

	const history = useHistory()

	// Validation
	// Validation
	const onValidation = () => {
		if (userPassword === '') {
			addToast('Por favor especifica tu contraseña', {
				appearance: 'warning',
			})
			setLastError('userPassword')
			return
		}
		if (userPasswordConfirm === '') {
			addToast('Por favor confirma tu contraseña', {
				appearance: 'warning',
			})
			setLastError('userPasswordConfirm')
			return
		}
		if (userPasswordConfirm !== userPassword) {
			addToast('Por favor confirma tu contraseña correctamente', {
				appearance: 'warning',
			})
			setLastError('userPasswordConfirm')
			return
		}
		return true
	}

	const { userLogin } = useContext(UserContext)

	const onSubmit = (e) => {
		e.preventDefault()
		if (onValidation()) {
			// req
			setLoadingBar(true)
			axios
				.post(
					`${platformURL}user/forgot/restore`,
					{ data: { userEmail, userPassword, userRecoverCode: recoverCode } },
					{
						headers: { Authorization: `Bearer ${platformBearerToken}` },
					}
				)
				.then((res) => {
					setLoadingBar(false)
					userLogin(res.data)
					setAlert({
						type: 'success',
						title: 'Cuenta recuperada',
						message: (
							<p>
								Tu nueva contraseña ha sido establecida <br />y has iniciado sesión de manera automática
							</p>
						),
					})
					history.push('/')
				})
				.catch((err) => {
					console.error(err)
					setLoadingBar(false)
					const error = err.response?.data?.error_txt || 'Error desconocido al establecer tu nueva contraseña'
					setAlert({
						type: 'error',
						title: 'Error al establecer tu nueva contraseña',
						message: error,
					})
				})
			// req
		}

		return false
	}

	// Effects
	// Effects
	useEffect(() => {
		if (recoverCode) {
			// req
			setLoadingBar(true)
			axios
				.get(`${platformURL}user/forgot/` + recoverCode, {
					headers: { Authorization: `Bearer ${platformBearerToken}` },
				})
				.then((res) => {
					setLoadingBar(false)
					setUserEmail(res.data.userObject.userEmail)
				})
				.catch((err) => {
					history.push('/')
					setLoadingBar(false)
					console.error(err)
					const error = err.response?.data?.error_txt || 'Error desconocido al recuperar tu cuenta'
					setAlert({
						type: 'error',
						title: 'Error al recuperar tu cuenta',
						message: error,
					})
				})
			// req
		}
	}, [])
	// Effects
	// Effects

	if (userEmail) {
		return (
			<Row>
				<Col md={{ span: 6, offset: 3 }} className="text-center">
					<Row>
						<Col md={12}>
							<h4>Crea una nueva contraseña</h4>
							<small>
								Por favor, introduce una nueva contraseña para tu cuenta <strong>{userEmail}</strong>
							</small>
						</Col>
					</Row>

					<Row>
						<Col md={12} className="pt-3">
							<Form id="se-form-restore" className="se-form at-form" onSubmit={onSubmit}>
								<Form.Group controlId="formBasicUserPassword">
									<Form.Control
										type="password"
										placeholder="Introduce aquí tu contraseña"
										value={userPassword}
										onChange={(e) => setUserPassword(e.target.value)}
										className={lastError === 'userPassword' && 'with-error'}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicUserPasswordConfirm">
									<Form.Control
										type="password"
										placeholder="Introduce aquí tu contraseña"
										value={userPasswordConfirm}
										onChange={(e) => setUserPasswordConfirm(e.target.value)}
										className={lastError === 'userPasswordConfirm' && 'with-error'}
									/>
								</Form.Group>
								<Button size="md" variant="success" type="submit" form="se-form-restore">
									<SiMinutemailer
										style={{
											position: 'relative',
											bottom: '1px',
											marginRight: '5px',
										}}
									/>
									Establecer contraseña e Iniciar sesión
								</Button>
							</Form>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	} else {
		return null
	}
}
