/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {
	switch (action.type) {
		case 'SET_ANCHORS':
			return {
				...state,
				anchors: action.payload,
			}
		case 'SET_CURRENT_ANCHOR':
			return {
				...state,
				currentAnchor: +action.payload,
			}
		case 'SET_CURRENT_PERIOD':
			return {
				...state,
				currentPeriod: +action.payload,
			}
		case 'SET_COURSES':
			return {
				...state,
				courses: action.payload,
			}
		case 'SET_EVENTS':
			return {
				...state,
				events: action.payload,
			}
		case 'SET_RUBRICA':
			return {
				...state,
				rubrica: action.payload,
			}
		case 'SET_PAYMENTS':
			return {
				...state,
				payments: action.payload,
			}
		case 'SET_CERTIFICATES':
			return {
				...state,
				certificates: action.payload,
			}
		case 'SET_BUSY':
			return {
				...state,
				busy: action.payload,
			}
		case 'RESET':
			return {
				...state,
				anchors: false,
				currentAnchor: false,
				currentPeriod: false,
				courses: false,
				events: false,
				rubrica: false,
				payments: false,
				certificates: false,
			}

		default:
			return state
	}
}
