import React from 'react'
import Badge from 'react-bootstrap/esm/Badge'
import Table from 'react-bootstrap/esm/Table'
export function GradeTable({ materia }) {
	return (
		<Table striped bordered hover>
			<thead>
				<tr>
					<th className="col-md-4">Criterio</th>
					<th className="col-md-4">Descripción</th>
					<th className="col-md-2 text-center">Valor</th>
					<th className="col-md-2 text-center">Calificación</th>
				</tr>
			</thead>
			<tbody className="se-grade">
				{materia.criterios.map((criterio, index) => {
					let criterioGradeRowClass = ''

					if (criterio.grade) {
						criterioGradeRowClass = criterio.grade >= 0 ? 'se-grade-fail' : 'se-grade-pass'
					} else {
						criterioGradeRowClass = ''
					}

					if (!criterio.meta) {
						return (
							<tr key={index}>
								<td>{criterio.name}</td>
								<td>{criterio.desc}</td>
								<td className="se-column-pct">{criterio.pct}%</td>
								<td className={criterioGradeRowClass}>
									{criterio.grade ? (
										criterio.grade
									) : (
										<Badge
											style={{
												backgroundColor: 'var(--se-dia-naranja)',
											}}
										>
											No calificado
										</Badge>
									)}
								</td>
							</tr>
						)
					} else {
						let criterioGradeRowClass = ''

						if (criterio.grade) {
							criterioGradeRowClass =
								criterio.grade >= 60 ? 'se-criterios-grade-row se-grade-pass' : 'se-criterios-grade-row se-grade-fail'
						} else {
							criterioGradeRowClass = ''
						}

						return (
							<tr className={criterioGradeRowClass}>
								<td colSpan={3}>Calificación total</td>
								<td>{criterio.grade}</td>
							</tr>
						)
					}
				})}
			</tbody>
		</Table>
	)
}
