import './CoursesPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Alert from 'react-bootstrap/esm/Alert'
import Row from 'react-bootstrap/esm/Row'

import { useHistory } from 'react-router-dom'
import SeMenu from '../components/SeMenu'
import SeHeroUser from '../components/SeHeroUser'
import { useContext, useEffect } from 'react'

import 'react-circular-progressbar/dist/styles.css'
import SeCourse from '../components/SeCourse'
import SePeriodSelector from '../components/SePeriodSelector'
import SePeriodHeader from '../components/SePeriodHeader'

import { modules } from '../classes/modules'

// Context
import { AnchorsContext } from '../context/shared/AnchorsContext'
import { UserContext } from '../context/shared/UserContext'

export default function CoursesPage() {
	const { anchors, currentAnchor, currentPeriod, courses } = useContext(AnchorsContext)
	const { user } = useContext(UserContext)
	const history = useHistory()

	return (
		<Container className="se-container-page">
			<SeHeroUser />
			<section className="se-section se-section-courses">
				<SeMenu anchors={anchors} currentAnchor={currentAnchor} modules={modules} />
				<div className="wrapper">
					<SePeriodSelector />

					{currentPeriod !== false && (
						<>
							<SePeriodHeader anchors={anchors} currentAnchor={currentAnchor} currentPeriod={currentPeriod} />
							<Row>
								<Col md={12} className="se-courses-container">
									{courses && courses.length === 0 && (
										<Row className="pt-3">
											<Col md={12} className="text-center">
												<Alert variant="warning">
													Este periodo aún no cuenta con cursos en línea. Te sugerimos regresar más tarde
												</Alert>
											</Col>
										</Row>
									)}
									{courses && courses.map((course, index) => <SeCourse key={index} index={index} course={course} />)}
								</Col>
							</Row>
						</>
					)}

					{/* main content */}
					{/* main content */}
				</div>
			</section>
		</Container>
	)
}
