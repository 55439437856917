import "./EstructuraPage.css";

import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";

import SeMenu from "../components/SeMenu";
import SeHeroUser from "../components/SeHeroUser";

import "react-circular-progressbar/dist/styles.css";

import { AiOutlineFileSync } from "react-icons/ai";
import { IconContext } from "react-icons/lib";

import parse from "html-react-parser";
import { useContext } from "react";
import { AnchorsContext } from "../context/shared/AnchorsContext";
import moment from "moment";
import { useState } from "react";
import { UserContext } from "../context/shared/UserContext";
import Alert from "react-bootstrap/esm/Alert";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import iCalendarPlugin from "@fullcalendar/icalendar";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";
import { BiCalendarCheck } from "react-icons/bi";

import CoreInfoForm from "../components/forms/CoreInfoForm";
import ContactoForm from "../components/forms/ContactoForm";
import { Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";

export default function ContactoPage({ setAlert, setLoadingBar }) {
  const { anchors, currentAnchor, setCurrentAnchor, events } =
    useContext(AnchorsContext);
  const { user } = useContext(UserContext);

  return (
    <Container className="se-container-page">
      <div className="wrapper">
        <Row>
          <Col md={12} className="text-center">
            <>
              <Link to="/">
                <Button
                  className="mb-3"
                  style={{
                    width: "auto",
                    backgroundColor: "var(--se-dia-azul)",
                    border: "0px",
                  }}
                >
                  <TiArrowBack
                    style={{ position: "relative", bottom: "2px" }}
                  />{" "}
                  Regresar al Inicio
                </Button>
              </Link>
            </>
            <h2 className="mb-4">Formulario de contacto</h2>
          </Col>
        </Row>
        <div
          className="se-estructura-container card"
          style={{ backgroundColor: "#E4E3D8" }}
        >
          <Row>
            <Col md={6} className="mt-2">
              <div className="wrapper">
                {
                  <ContactoForm
                    setAlert={setAlert}
                    setLoadingBar={setLoadingBar}
                  ></ContactoForm>
                }
              </div>
            </Col>
            <Col md={6} className="mt-2 p-4 pt-5">
              <h1 className="mb-4">
                <strong>Queremos escucharte y conocer tus intereses</strong>
              </h1>
              <h5 className="mt-3">
                Escríbenos y nos pondremos en contacto contigo
              </h5>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}
