import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'

export default function SePeriodHeader({ anchors, currentAnchor, currentPeriod }) {
	const canDisplay = () => {
		if (currentAnchor && currentPeriod) {
			if (anchors[currentAnchor].periods[currentPeriod]) {
				if (anchors[currentAnchor].periods[currentPeriod]._meta) {
					return true
				}
			}
		}
		return false
	}
	return (
		<>
			{anchors && anchors[currentAnchor]?.periods[currentPeriod]?._meta && (
				<Row>
					<Col md={12}>
						<hr />
						<div className="card p-3 mb-3 shadow-sm">
							{anchors[currentAnchor].periods[currentPeriod]._meta.name && (
								<h4 className="mb-1">{anchors[currentAnchor].periods[currentPeriod]._meta.name}</h4>
							)}

							{anchors[currentAnchor].periods[currentPeriod]._meta.dates.start && (
								<p className="mb-0">
									{anchors[currentAnchor].periods[currentPeriod]._meta.dates.start} -{' '}
									{anchors[currentAnchor].periods[currentPeriod]._meta.dates.end}
								</p>
							)}
						</div>
					</Col>
				</Row>
			)}
		</>
	)
}
