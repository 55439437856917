import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Form from 'react-bootstrap/esm/Form'
import Alert from 'react-bootstrap/esm/Alert'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'

export default function SeAnchorSelector() {
	const { anchors, currentAnchor, setCurrentAnchor } = useContext(AnchorsContext)
	return (
		<>
			{currentAnchor !== false ? (
				<Row>
					<Col md={{ span: 6, offset: 3 }} className="text-center pb-4">
						<Form.Group controlId="formBasicUserCountry">
							<Form.Label>Selecciona un trayecto formativo</Form.Label>
							<Form.Control
								className="form-select se-anchor-selector"
								as="select"
								placeholder="Selecciona un periodo"
								value={currentAnchor}
								onChange={(e) => setCurrentAnchor(e.target.value)}
							>
								{anchors?.length > 0 ? (
									anchors.map((anchor, index) => (
										<option value={+index} key={index}>
											{anchor._meta.name}
										</option>
									))
								) : (
									<option value={false}>No se encontraron planes de estudio</option>
								)}
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>
			) : (
				<Row className="pt-3">
					<Col md={12} className="text-center">
						<Alert variant="warning">No se encontraron planes de estudio</Alert>
					</Col>
				</Row>
			)}
		</>
	)
}
