import { Access } from './shared/Access'
import './MainPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import SeMainElement from '../components/SeMainElement'
import SeHeroUser from '../components/SeHeroUser'
import SeLogin from '../components/shared/SeLogin'
import SeSignup from '../components/shared/SeSignup'
import SeForgot from '../components/shared/SeForgot'
import { Link, Route, useParams } from 'react-router-dom'
import SeRestore from '../components/shared/SeRestore'
import { useContext } from 'react'
import { UserContext } from '../context/shared/UserContext'
import UserLoadingRow from '../components/shared/UserLoadingRow'
import { modules } from '../classes/modules'
import { AnchorsContext } from '../context/shared/AnchorsContext'

export default function MainPage({ setLoadingBar, setAlert }) {
	const { recoverCode } = useParams() || false
	const { user, userBusy } = useContext(UserContext)
	const { anchors, currentAnchor } = useContext(AnchorsContext)

	const iterateInModules = () => {
		const list = []
		modules.map((module, index) => {
			if (!anchors[currentAnchor]?._meta.hasCourses && module.id === 'courses') {
				return null
			}
			if (!anchors[currentAnchor]?._meta.hasCalendario && module.id === 'calendar') {
				return null
			}
			if (!anchors[currentAnchor]?._meta.hasCertificates && module.id === 'certificates') {
				return null
			}
			if (!anchors[currentAnchor]?._meta.hasPayments && module.id === 'payments') {
				return null
			}
			if (!anchors[currentAnchor]?._meta.hasRubricas && module.id === 'rubrica') {
				return null
			}
			if (!anchors[currentAnchor]?._meta.hasTramites && module.id === 'tramites') {
				return null
			}
			if (!anchors[currentAnchor]?._meta.hasRecordings && module.id === 'grabaciones') {
				return null
			}
			if (!anchors[currentAnchor]?._meta.hasEstructura && module.id === 'estructura') {
				return null
			}
			list.push(module)
		})

		return list
	}
	const activeModulesForDisplay = iterateInModules()

	return (
		<section className="se-page se-section se-section-main">
			<Container className="se-container-page" style={{ paddingTop: '30px' }}>
				{userBusy && <UserLoadingRow />}
				{user && (
					<>
						<SeHeroUser />
						<Row className="">
							{activeModulesForDisplay.map((module, index) => {
								return (
									<SeMainElement
										key={index}
										title={module.name}
										subtitle={module.desc}
										icon={module.icon}
										color={module.color}
										route={module.route}
										activeModulesForDisplay={activeModulesForDisplay}
									/>
								)
							})}
						</Row>
					</>
				)}
				<Access setLoadingBar={setLoadingBar} setAlert={setAlert} />
			</Container>
		</section>
	)
}
