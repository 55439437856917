import Container from 'react-bootstrap/esm/Container'
import Nav from 'react-bootstrap/esm/Nav'
import Navbar from 'react-bootstrap/esm/Navbar'
import NavDropdown from 'react-bootstrap/esm/NavDropdown'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { FiLogOut } from 'react-icons/fi'

import logoPrincipalInstitutoDIA from '../../assets/img/logos/IMP.png'
import { useContext, useEffect } from 'react'
import { UserContext } from '../../context/shared/UserContext'
import { AnchorsContext } from '../../context/shared/AnchorsContext'

export default function SeHeader() {
	const { addToast } = useToasts()
	const { user, userBusy, userLogout } = useContext(UserContext)
	const { reset } = useContext(AnchorsContext)
	const handleUserLogout = () => {
		if (reset) reset()
		userLogout()
		addToast(
			<p>
				Muchas gracias. <br />
				Hemos terminado tu sesión
			</p>,
			{
				appearance: 'info',
			}
		)
	}
	useEffect(() => {
		console.log(user)
		console.log(userBusy)
	}, [user, userBusy])
	return (
		<Navbar className="navbar-se navbar-white se-bar" expand="lg">
			<Container>
				<Navbar.Brand>
					<Link to="/">
						<img alt="Logo Instituto DIA" src={logoPrincipalInstitutoDIA} />
					</Link>
				</Navbar.Brand>
				<Navbar.Toggle className="navbar" aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					<Nav>
						<Nav.Link as={Link} to="/">
							Inicio
						</Nav.Link>
						<Nav.Link as={Link} to="/contacto">
							Contacto
						</Nav.Link>

						{user && (
							<>
								<NavDropdown title={user.userEmail} id="basic-nav-dropdown">
									<NavDropdown.Item as={Link} to="/informacion-basica" key={0}>
										Mi información
									</NavDropdown.Item>

									<NavDropdown.Item as={Link} to="/historial-de-formacion" key={1}>
										Historial de formación
									</NavDropdown.Item>

									<NavDropdown.Divider key={2} />

									<NavDropdown.Item key={3} onClick={handleUserLogout}>
										<FiLogOut className="se-icon-logout" />
										Cerrar sesión
									</NavDropdown.Item>
								</NavDropdown>
							</>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
