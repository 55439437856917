import { Grade } from './../components/Grade'
import './RubricaPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Alert from 'react-bootstrap/esm/Alert'
import Row from 'react-bootstrap/esm/Row'

import { useHistory } from 'react-router-dom'
import SeMenu from '../components/SeMenu'
import SeHeroUser from '../components/SeHeroUser'
import { useEffect } from 'react'

import 'react-circular-progressbar/dist/styles.css'
import SePeriodSelector from '../components/SePeriodSelector'
import SePeriodHeader from '../components/SePeriodHeader'

import { GoTasklist } from 'react-icons/go'
import { GiUpgrade } from 'react-icons/gi'
import { IconContext } from 'react-icons/lib'
import Button from 'react-bootstrap/esm/Button'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'
import { UserContext } from '../context/shared/UserContext'

export default function RubricaPage() {
	const { anchors, currentAnchor, currentPeriod, rubrica } = useContext(AnchorsContext)

	const isPageReady = anchors !== false && anchors[currentAnchor]?.rubrica && anchors[currentAnchor]?.rubrica ? true : false

	return (
		<Container className="se-container-page">
			<SeHeroUser />
			<section className="se-section se-section-rubrica">
				<SeMenu />
				<div className="wrapper">
					<SePeriodSelector />

					{currentPeriod !== false && (
						<>
							<SePeriodHeader />
							<Row>
								<Col md={12} className="se-rubrica-container pt-2">
									{rubrica && (
										<>
											<Row>
												<Col md={1} style={{ padding: '5px 30px' }}>
													<IconContext.Provider value={{ color: 'gray', size: '40px' }}>
														<div>
															<GoTasklist />
														</div>
													</IconContext.Provider>
												</Col>
												<Col md={11}>
													<h4 className="mb-0">Asignaturas</h4>
													<h6>{rubrica._meta.name}</h6>
													<p>{rubrica._meta.description}</p>
												</Col>
											</Row>

											{rubrica.materias.map((materia, index) => (
												<Grade index={index} key={index} materia={materia} />
											))}
										</>
									)}
									{rubrica === false && (
										<Row className="pt-3">
											<Col md={12} className="text-center">
												<Alert variant="warning">
													Este periodo aún no cuenta con criterios de evaluación. Te sugerimos regresar más tarde
												</Alert>
											</Col>
										</Row>
									)}
									<hr />
									<Row className="pt-2">
										<Col md={1} style={{ padding: '5px 30px' }}>
											<IconContext.Provider value={{ color: 'gray', size: '40px' }}>
												<div>
													<GiUpgrade />
												</div>
											</IconContext.Provider>
										</Col>
										<Col md={11}>
											<h4 className="mb-0">Evaluación de la asignatura</h4>
											<h6>Consulta aquí tus calificaciones y descarga tu boleta</h6>
										</Col>
									</Row>
									<Row className="pt-3">
										{rubrica?._meta?.printReady ? (
											<Col md={12} className="text-center">
												<a href={rubrica._meta.print}>
													<Button variant="success">Descargar Acta de Calificaciones</Button>
												</a>
											</Col>
										) : (
											<Col md={12} className="text-center">
												<Alert variant="warning">
													Lo sentimos, aún no es posible descargar tu boleta. Te sugerimos regresar más tarde
												</Alert>
											</Col>
										)}
									</Row>
								</Col>
							</Row>
						</>
					)}

					{/* main content */}
					{/* main content */}
				</div>
			</section>
		</Container>
	)
}
