import './RubricaPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Table from 'react-bootstrap/esm/Table'
import Alert from 'react-bootstrap/esm/Alert'
import Row from 'react-bootstrap/esm/Row'

import { useHistory, useLocation } from 'react-router-dom'
import SeMenu from '../components/SeMenu'
import SeHeroUser from '../components/SeHeroUser'
import { useEffect } from 'react'
import Button from 'react-bootstrap/esm/Button'

import 'react-circular-progressbar/dist/styles.css'

import { BsStar } from 'react-icons/bs'
import { IconContext } from 'react-icons/lib'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'
import { UserContext } from '../context/shared/UserContext'

export default function HistorialPage() {
	const { anchors, currentAnchor, certificates } = useContext(AnchorsContext)
	const { user } = useContext(UserContext)

	return (
		<Container className="se-container-page">
			<SeHeroUser />
			<section className="se-section se-section-rubrica">
				<div className="wrapper">
					<Row className="pt-3">
						<Col md={1} style={{ padding: '5px 30px' }}>
							<IconContext.Provider value={{ color: 'gray', size: '40px' }}>
								<div>
									<BsStar />
								</div>
							</IconContext.Provider>
						</Col>
						<Col md={11}>
							<h4 className="mb-0">Historial de formación</h4>
							<p>
								Este es el historial formativo de <u>{user.userEmail}</u>
							</p>
						</Col>
					</Row>
					{certificates?.length > 0 ? (
						<Row>
							<Col md={12} className="pt-3">
								<Table striped bordered hover>
									<thead>
										<tr>
											<th className="col-md-6">Curso</th>
											<th className="col-md-2">Fecha</th>
											<th className="col-md-2">Constancia</th>
										</tr>
									</thead>
									<tbody>
										{certificates.map((certificate, index) => (
											<tr key={index}>
												<td>{certificate.name}</td>
												<td>{certificate.start}</td>
												<td>
													<a href={certificate.url}>
														<Button
															size="sm"
															variant="success"
															style={{ backgroundColor: '#87c053', color: '#000', borderColor: 'transparent' }}
														>
															<AiOutlineFilePdf style={{ position: 'relative', bottom: '1px' }} />
															&nbsp;Descargar PDF
														</Button>
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</Col>
						</Row>
					) : (
						<Row className="pt-3">
							<Col md={12} className="text-center">
								<Alert variant="warning">Lo sentimos, no tenemos registro de ningún curso en el que hayas participado</Alert>
							</Col>
						</Row>
					)}

					{/* main content */}
					{/* main content */}
				</div>
			</section>
		</Container>
	)
}
