import './GrabacionesPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Alert from 'react-bootstrap/esm/Alert'

import { Link, useHistory, useLocation } from 'react-router-dom'
import SeMenu from '../components/SeMenu'
import SeHeroUser from '../components/SeHeroUser'
import { useEffect } from 'react'

import 'react-circular-progressbar/dist/styles.css'

import { BiVideoRecording } from 'react-icons/bi'
import { IconContext } from 'react-icons/lib'
import { FaPlay } from 'react-icons/fa'

import parse from 'html-react-parser'
import Button from 'react-bootstrap/esm/Button'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'

export default function GrabacionesPage() {
	const { anchors, currentAnchor } = useContext(AnchorsContext)

	const parsedContent = () => {
		return parse(anchors[currentAnchor].tramites)
	}

	return (
		<Container className="se-container-page">
			<SeHeroUser />
			<section className="se-section se-section-recordings">
				<SeMenu />
				<div className="wrapper">
					<Row>
						<Col md={1} style={{ padding: '5px 30px' }}>
							<IconContext.Provider value={{ color: 'gray', size: '40px' }}>
								<div>
									<BiVideoRecording />
								</div>
							</IconContext.Provider>
						</Col>
						<Col md={11}>
							<h4 className="mb-0">Grabaciones publicadas</h4>
							<p>Tienes permiso de consultar las siguientes grabaciones</p>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							{currentAnchor !== false && anchors[currentAnchor].recordings?.length > 0 ? (
								<Row>
									{anchors[currentAnchor].recordings.map((record, index) => {
										if (!record.zoomUrl) {
											return null
										}
										return (
											<Col md={12} className="" key={index}>
												<div className="card record-loop">
													<h4>{record.name}</h4>
													<p>
														{record.start} - {record.end}
													</p>
													<div>{parse(record.description)}</div>

													<Row>
														<Col md={5}>
															<p>
																<a href={record.zoomRecordingUrl} target="_blank" rel="noreferrer">
																	<Button variant="success">
																		<FaPlay /> Ver grabación
																	</Button>
																</a>
															</p>
														</Col>
														{record.zoomRecordingPassword != '' && (
															<Col md={7}>
																<p className="pt-2">
																	Contraseña: <span className="text-hint">{record.zoomRecordingPassword}</span>
																</p>
															</Col>
														)}
													</Row>
												</div>
											</Col>
										)
									})}
								</Row>
							) : (
								<Row>
									<Col md={12} className="text-center">
										<Alert variant="warning">No se encontraron grabaciones publicadas. Por favor regresa más tarde</Alert>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
				</div>
			</section>
		</Container>
	)
}
