import React from 'react'
import { Route } from 'react-router'
import BasePageAnchorAware from './shared/BasePageAnchorAware'
import CalendarPage from './CalendarPage'
import CertificatesPage from './CertificatesPage'
import CoreInfoPage from './CoreInfoPage'
import CoursesPage from './CoursesPage'
import GrabacionesPage from './GrabacionesPage'
import MainPage from './MainPage'
import PaymentsPage from './PaymentsPage'
import RubricaPage from './RubricaPage'
import TramitesPage from './TramitesPage'
import EstructuraPage from './EstructuraPage'
import HistorialPage from './HistorialPage'
import ContactoPage from './ContactoPage'

export function RouterLoop({ setLoadingBar, setAlert }) {
	return (
		<>
			<Route path={['/', '/recuperar-cuenta', '/recuperar-cuenta/:recoverCode']} exact>
				<MainPage setLoadingBar={setLoadingBar} setAlert={setAlert} />
			</Route>
			{/* 2023 */}
			<Route path={['/contacto']} render={(props) => <ContactoPage setLoadingBar={setLoadingBar} setAlert={setAlert} />} />
			<Route
				path={['/informacion-basica']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<CoreInfoPage setAlert={setAlert} setLoadingBar={setLoadingBar} />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/historial-de-formacion']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<HistorialPage></HistorialPage>
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/calendario']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<CalendarPage />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/cursos']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<CoursesPage />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/calificaciones']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<RubricaPage />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/pagos']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<PaymentsPage />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/certificados']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<CertificatesPage />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/tramites']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<TramitesPage />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/grabaciones']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<GrabacionesPage />
					</BasePageAnchorAware>
				)}
			/>
			<Route
				path={['/estructura']}
				render={(props) => (
					<BasePageAnchorAware requeriments={['user']}>
						<EstructuraPage />
					</BasePageAnchorAware>
				)}
			/>
		</>
	)
}
