import Button from 'react-bootstrap/esm/Button'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { CircularProgressbar } from 'react-circular-progressbar'

export default function SeCourse({ course, index }) {
	const NewlineText = (props) => {
		const text = props.text
		return text.split('\n').map((str, index) => <p key={index}>{str}</p>)
	}

	const BotonIniciarTexto = (percentage) => {
		if (percentage.percentage == 0) {
			return 'Iniciar curso'
		} else {
			return 'Continuar curso'
		}
	}

	return (
		<Row key={index} className="se-courses-course-row">
			<Col className="course-left" md={4}>
				{course.image && (
					<Row>
						<Col md={12}>
							<a href={course.url} target="_blank" rel="noreferrer">
								<img src={course.image} alt="Imagen de Curso" />
							</a>
						</Col>
					</Row>
				)}
				<Row className="pt-3 se-course-left-container">
					<Col md={4} xs={6} className="se-course-progress-container">
						<div style={{ width: '75px', height: '75px' }} className="text-center">
							<CircularProgressbar
								className="se-course-progress"
								value={course.progress.percentage}
								text={`${course.progress.percentage}%`}
							/>
						</div>
					</Col>
					<Col md={8} xs={6} className="text-center pt-3 se-course-button-container">
						<a href={course.url} target="_blank" rel="noreferrer">
							<Button variant="success" style={{ backgroundColor: course.hex, border: '0px', color: '#FFF' }}>
								<BotonIniciarTexto percentage={course.progress.percentage} />
							</Button>
						</a>
					</Col>
				</Row>
			</Col>
			<Col className="course-right" md={8} style={{ borderTopColor: course.hex }}>
				<h5>{course.name}</h5>
				<div className="course-description">
					<NewlineText text={course.description}></NewlineText>
				</div>
			</Col>
		</Row>
	)
}
