import Container from 'react-bootstrap/esm/Container'
import Nav from 'react-bootstrap/esm/Nav'
import Navbar from 'react-bootstrap/esm/Navbar'
import NavDropdown from 'react-bootstrap/esm/NavDropdown'
import { FaRegIdCard, FaRegCalendarAlt, FaMoneyCheckAlt } from 'react-icons/fa'
import { GoTasklist } from 'react-icons/go'
import { AiOutlineCheckCircle, AiOutlineStar, AiOutlineFileDone } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/esm/Col'

export default function SeMainElement({ icon, title, subtitle, color, route, activeModulesForDisplay }) {
	title = title || 'No title'
	subtitle = subtitle || 'No subtitle'
	const columnCount = activeModulesForDisplay.length === 1 ? 4 : null
	return (
		<Col className="se-main-element" md={{ span: 4, offset: columnCount }}>
			<Link to={route}>
				<div className="card text-center">
					<div className="element-icon">{icon}</div>
					<h4>{title}</h4>
					<p>{subtitle}</p>
				</div>
			</Link>
		</Col>
	)
}
