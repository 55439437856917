import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Form from 'react-bootstrap/esm/Form'
import { useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { SiMinutemailer } from 'react-icons/si'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { appURL, platformBearerToken, platformURL } from '../../classes/Environment'
import axios from 'axios'

export default function SeForgot({ setLoadingBar, setAlert }) {
	// const [userEmail, setUserEmail] = useState('ricardo@tequilastudios.com')
	const [userEmail, setUserEmail] = useState('')

	const [lastError, setLastError] = useState(false)
	const { addToast } = useToasts()

	const history = useHistory()

	// Validation
	// Validation
	const onValidation = () => {
		if (userEmail === '') {
			addToast('Por favor especifica tu correo electrónico', {
				appearance: 'warning',
			})
			setLastError('userEmail')
			return
		}
		return true
	}

	// Context
	// Context
	const handleForgot = (data, callback) => {
		data.origin = appURL + '/recuperar-cuenta/'
		// req
		setLoadingBar(true)
		axios
			.post(
				`${platformURL}user/forgot`,
				{ data },
				{
					headers: { Authorization: `Bearer ${platformBearerToken}` },
				}
			)
			.then((res) => {
				setLoadingBar(false)
				callback()
			})
			.catch((err) => {
				setLoadingBar(false)
				const error = err.response?.data?.error_txt || 'Error desconocido al registrarte'
				setAlert({
					type: 'error',
					title: 'Error al registrarte',
					message: error,
				})
			})
		// req

		return true
	}
	// Context
	// Context

	const onSubmit = (e) => {
		e.preventDefault()
		if (onValidation()) {
			handleForgot({ userEmail: userEmail }, () => {
				history.push('/')
				setAlert({
					type: 'success',
					title: 'Correo enviado',
					message: 'The hemos enviado un correo electrónico con las instrucciones para recuperar tu contraseña',
				})
			})
		}

		return false
	}
	return (
		<Row>
			<Col md={{ span: 6, offset: 3 }} className="text-center">
				<Row>
					<Col md={12}>
						<h4>Olvidaste tu contraseña?</h4>
						<small>Introduce aquí tu correo electrónico para recuperarla</small>
					</Col>
				</Row>

				<Row>
					<Col md={12} className="pt-3">
						<Form id="se-form-forgot" className="se-form at-form" onSubmit={onSubmit}>
							<Form.Group controlId="formBasicUserEmail">
								<Form.Control
									type="text"
									placeholder="Introduce aquí tu correo electrónico"
									value={userEmail}
									onChange={(e) => setUserEmail(e.target.value)}
									className={lastError === 'userEmail' && 'with-error'}
								/>
							</Form.Group>
							<Button size="md" variant="warning" type="submit" form="se-form-forgot">
								<SiMinutemailer
									style={{
										position: 'relative',
										bottom: '1px',
										marginRight: '5px',
									}}
								/>
								Recuperar contraseña
							</Button>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
