import './TramitesPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'

import SeMenu from '../components/SeMenu'
import SeHeroUser from '../components/SeHeroUser'

import 'react-circular-progressbar/dist/styles.css'

import { AiOutlineFileSync } from 'react-icons/ai'
import { IconContext } from 'react-icons/lib'

import parse from 'html-react-parser'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'

export default function TramitesPage() {
	const { anchors, currentAnchor } = useContext(AnchorsContext)

	const parsedContent = () => {
		return parse(anchors[currentAnchor]?.tramites)
	}

	return (
		<Container className="se-container-page">
			<SeHeroUser />
			<section className="se-section se-section-tramites">
				<SeMenu />
				<div className="wrapper">
					<Row>
						<Col md={1} style={{ padding: '5px 30px' }}>
							<IconContext.Provider value={{ color: 'gray', size: '40px' }}>
								<div>
									<AiOutlineFileSync />
								</div>
							</IconContext.Provider>
						</Col>
						<Col md={11}>
							<h4 className="mb-0">Trámites generales</h4>
						</Col>
					</Row>
					{currentAnchor !== false && (
						<Row>
							<Col md={12} className="pt-4">
								<Row>
									{anchors[currentAnchor]?.tramitesFiles?.TramitesBaja && (
										<Col md={4} className="mb-3">
											<a href={anchors[currentAnchor]?.tramitesFiles?.TramitesBaja} target="_blank" rel="noreferrer">
												<div className="se-tramites-container card text-center">Descarga Formato de Baja</div>
											</a>
										</Col>
									)}
									{anchors[currentAnchor]?.tramitesFiles?.TramitesReglamento && (
										<Col md={4} className="mb-3">
											<a href={anchors[currentAnchor]?.tramitesFiles?.TramitesReglamento} target="_blank" rel="noreferrer">
												<div className="se-tramites-container card text-center">Descarga Reglamento de IMP</div>
											</a>
										</Col>
									)}
									{anchors[currentAnchor]?.tramitesFiles?.TramitesManualSE && (
										<Col md={4} className="mb-3">
											<a href={anchors[currentAnchor]?.tramitesFiles?.TramitesManualSE} target="_blank" rel="noreferrer">
												<div className="se-tramites-container card text-center">Descarga Manual de Plataforma de SE</div>
											</a>
										</Col>
									)}

									{anchors[currentAnchor]?.tramitesFiles?.TramitesManualPagos && (
										<Col md={4} className="mb-3">
											<a href={anchors[currentAnchor]?.tramitesFiles?.TramitesManualPagos} target="_blank" rel="noreferrer">
												<div className="se-tramites-container card text-center">Descarga Manual de Plataforma de Pagos</div>
											</a>
										</Col>
									)}
									{anchors[currentAnchor]?.tramitesFiles?.TramitesTitulacion && (
										<Col md={4} className="mb-3">
											<a href={anchors[currentAnchor]?.tramitesFiles?.TramitesTitulacion} target="_blank" rel="noreferrer">
												<div className="se-tramites-container card text-center">Descarga Trámite de titulación</div>
											</a>
										</Col>
									)}
									{anchors[currentAnchor]?.tramitesFiles?.TramitesHistorial && (
										<Col md={4} className="mb-3">
											<a href={anchors[currentAnchor]?.tramitesFiles?.TramitesHistorial} target="_blank" rel="noreferrer">
												<div className="se-tramites-container card text-center">Descarga Historial académico</div>
											</a>
										</Col>
									)}
								</Row>
							</Col>
						</Row>
					)}
				</div>
			</section>
		</Container>
	)
}
