import './CoreInfoPage.css'

import Container from 'react-bootstrap/esm/Container'

import { useHistory } from 'react-router-dom'
import SeMenu from '../components/SeMenu'
import CoreInfoForm from '../components/forms/CoreInfoForm'
import SeHeroUser from '../components/SeHeroUser'
import { useEffect } from 'react'
import { useContext } from 'react'
import { UserContext } from '../context/shared/UserContext'

export default function CoreInfoPage({ modules, setAlert, setLoadingBar }) {
	const { user } = useContext(UserContext)

	return (
		<>
			<Container className="se-container-page">
				<SeHeroUser />
				<section className="se-section se-section-core-info">
					<SeMenu />
					<div className="wrapper">{user && <CoreInfoForm setAlert={setAlert} setLoadingBar={setLoadingBar}></CoreInfoForm>}</div>
				</section>
			</Container>
		</>
	)
}
