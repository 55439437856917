import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import UserLoadingRow from '../../components/shared/UserLoadingRow'
import { AnchorsContext } from '../../context/shared/AnchorsContext'
import { UserContext } from '../../context/shared/UserContext'

export default function BasePageAnchorAware({ children, requeriments }) {
	const { user, userBusy } = useContext(UserContext)
	const { busy } = useContext(AnchorsContext)
	const history = useHistory()

	useEffect(() => {
		console.log('UE BASEPAGE', user)
		if (requeriments.includes('user')) {
			if (!userBusy && user === false) {
				history.push('/')
			}
		}
	}, [user])

	return <>{userBusy || busy ? <UserLoadingRow /> : children}</>
}
