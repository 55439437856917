import { RouterLoop } from './pages/RouterLoop'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import './css/se-root.css'
// import './css/se-responsive.css'

// React
import { BrowserRouter as Router } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'

// Bootstrap
import Container from 'react-bootstrap/Container/'

// vendor
import { ToastProvider } from 'react-toast-notifications'
import TopBarProgress from 'react-topbar-progress-indicator'
import SweetAlert from 'react-bootstrap-sweetalert'

// Custom components
import SeHeader from './components/shared/SeHeader'
import SeHero from './components/shared/SeHero'
import SeFooter from './components/shared/SeFooter'

// Utils

// Context
import { UserProvider } from './context/shared/UserContext'
import { AnchorsProvider } from './context/shared/AnchorsContext'

function App() {
	// Loader
	// Loader
	const [loadingBar, setLoadingBar] = useState(false)
	TopBarProgress.config({
		barColors: {
			0: '#1e7e34',
			'1.0': '#1e7e34',
		},
		barThickness: 5,
		shadowBlur: 5,
	})

	// SweetAlert
	// SweetAlert
	const [alert, setAlert] = useState(false)
	const alertHide = () => {
		setAlert(false)
	}

	// Effects
	// Effects
	useEffect(() => {
		// document.title = 'Servicios Escolares Instituto DIA ' + appVersion
		document.title = 'Servicios Escolares Instituto DIA'
	}, [])

	return (
		<UserProvider>
			<Router>
				<ToastProvider autoDismiss placement="bottom-left">
					<div>{loadingBar && <TopBarProgress />}</div>
					<AnchorsProvider>
						<SeHeader></SeHeader>
						<Container className="se-container" fluid>
							<SeHero />
							<RouterLoop setLoadingBar={setLoadingBar} setAlert={setAlert} />
							<div className="push"></div>
						</Container>
						<SeFooter></SeFooter>
					</AnchorsProvider>
				</ToastProvider>
				{alert && (
					<SweetAlert type={alert.type} title={alert.title} onConfirm={alertHide}>
						{alert.message}
					</SweetAlert>
				)}
			</Router>
		</UserProvider>
	)
}

export default App
