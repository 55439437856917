import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

export default function UserLoadingRow() {
	return (
		<Row>
			<Col md={12}>
				<Container>
					<div className="se-user-loading-row card">
						<h4>Espera un momento</h4>
						<h6>Estamos cargando tu información...</h6>
						<Loader className="se-user-loading-spinner" type="Oval" color="#87c053" height={100} width={100} />
					</div>
				</Container>
			</Col>
		</Row>
	)
}
