import './CoreInfoForm.css'

// Bootstrap
import Row from 'react-bootstrap/esm/Row'
import Form from 'react-bootstrap/esm/Form'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'

import { useEffect, useState } from 'react'

import { AiOutlineIdcard } from 'react-icons/ai'
import { BsCheckCircle } from 'react-icons/bs'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { GiHealthPotion } from 'react-icons/gi'
import { GoKey } from 'react-icons/go'
import { MdPhotoCamera } from 'react-icons/md'

import { countries } from '../../classes/countries'
import { estados } from '../../classes/estados'

import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import Alert from 'react-bootstrap/esm/Alert'
import axios from 'axios'
import { platformBearerToken, platformURL } from '../../classes/Environment'
import { useContext } from 'react'
import { UserContext } from '../../context/shared/UserContext'

export default function CoreInfoForm({ setAlert, setLoadingBar }) {
	const { user, userToken, userLogin } = useContext(UserContext)

	// ******* Campos usuario *****
	// ******* Campos usuario *****
	const [userName, setUserName] = useState(user.userName)
	const [userNameFirst, setUserNameFirst] = useState(user.userNameFirst)
	const [userNameLast, setUserNameLast] = useState(user.userNameLast)
	const [userDOB, setUserDOB] = useState(user.userDOB || '')
	const [userCURP, setUserCURP] = useState(user.userCURP || '')

	const [userCountry, setUserCountry] = useState(user.userCountry)
	const [userState, setUserState] = useState(user.userState)
	const [userCity, setUserCity] = useState(user.userCity || '')
	const [userCP, setUserCP] = useState(user.userCP || '')
	const [userLocation, setUserLocation] = useState(user.userLocation || '')
	const [userPhone, setUserPhone] = useState(user.userPhone || '')

	const [userEmergencyName, setUserEmergencyName] = useState(user.userEmergencyName || '')
	const [userEmergencyPhone, setUserEmergencyPhone] = useState(user.userEmergencyPhone || '')
	const [userEmergencyRisks, setUserEmergencyRisks] = useState(user.userEmergencyRisks || '')

	const [userEmail, setUserEmail] = useState(user.userEmail)
	const [userPassword, setUserPassword] = useState('')
	const [userPasswordConfirm, setUserPasswordConfirm] = useState('')

	// Funcionamiento formulario
	const [lastError, setlastError] = useState(false)
	const { addToast } = useToasts()

	const history = useHistory()

	useEffect(() => {
		updateForm()
	}, [user])

	const updateForm = () => {
		setUserName(user.userName)
		setUserNameFirst(user.userNameFirst)
		setUserNameLast(user.userNameLast)
		setUserDOB(user.userDOB || '')
		setUserCURP(user.userCURP || '')

		setUserCountry(user.userCountry)
		setUserState(user.userState)
		setUserCity(user.userCity || '')
		setUserCP(user.userCP || '')
		setUserLocation(user.userLocation || '')
		setUserPhone(user.userPhone || '')

		setUserEmergencyName(user.userEmergencyName || '')
		setUserEmergencyPhone(user.userEmergencyPhone || '')
		setUserEmergencyRisks(user.userEmergencyRisks || '')

		setUserEmail(user.userEmail)
		setUserPassword('')
		setUserPasswordConfirm('')
	}

	// Validation
	// Validation
	const onValidation = () => {
		if (userName === '') {
			addToast('Por favor especifica tu(s) nombre(s)', {
				appearance: 'warning',
			})
			setlastError('userName')
			return
		}
		if (userNameFirst === '') {
			addToast('Por favor especifica tu apellido paterno', {
				appearance: 'warning',
			})
			setlastError('userNameFirst')
			return
		}
		if (userNameLast === '') {
			addToast('Por favor especifica tu apellido materno', {
				appearance: 'warning',
			})
			setlastError('userNameLast')
			return
		}
		// if (userDOB === '') {
		// 	addToast('Por favor especifica tu fecha de Nacimiento', {
		// 		appearance: 'warning',
		// 	})
		// 	setlastError('userDOB')
		// 	return
		// }

		if (userCountry === '' || userCountry === '0') {
			addToast('Por favor especifica tu País', {
				appearance: 'warning',
			})
			setlastError('userCountry')
			return
		}
		if (userState === '' || userState === '0' || !userState) {
			addToast('Por favor especifica tu Estado', {
				appearance: 'warning',
			})
			setlastError('userState')
			return
		}
		// if (userCity === '') {
		// 	addToast('Por favor especifica tu Ciudad o Población', {
		// 		appearance: 'warning',
		// 	})
		// 	setlastError('userCity')
		// 	return
		// }
		// if (userCP === '') {
		// 	addToast('Por favor especifica tu Código Postal', {
		// 		appearance: 'warning',
		// 	})
		// 	setlastError('userCP')
		// 	return
		// }
		// if (userLocation === '') {
		// 	addToast('Por favor especifica tu Localidad, Delegación o Municipio', {
		// 		appearance: 'warning',
		// 	})
		// 	setlastError('userLocation')
		// 	return
		// }
		if (userEmail === '') {
			addToast('Por favor especifica tu correo electrónico', {
				appearance: 'warning',
			})
			setlastError('userEmail')
			return
		}
		if (userPassword !== '') {
			if (userPassword === '') {
				addToast('Por favor especifica tu nueva contraseña', {
					appearance: 'warning',
				})
				setlastError('userPassword')
				return
			}
			if (userPasswordConfirm === '') {
				addToast('Por favor confirma tu nueva contraseña', {
					appearance: 'warning',
				})
				setlastError('userPasswordConfirm')
				return
			}
			if (userPasswordConfirm !== userPassword) {
				addToast('Por favor confirma correctamente tu nueva contraseña', {
					appearance: 'warning',
				})
				setlastError('userPasswordConfirm')
				return
			}
		}

		/* if (userCURP === "") {
			addToast("Por favor especifica tu CURP", {
				appearance: "warning",
			});
			setlastError("userCURP");
			return;
		} */
		/* if (userPhone === "") {
			addToast("Por favor especifica tu teléfono", {
				appearance: "warning",
			});
			setlastError("userPhone");
			return;
		} */

		return true
	}

	const onSubmit = (e) => {
		e.preventDefault()
		setlastError(false)

		// validation
		if (onValidation()) {
			const data = {
				// from State
				userToken: user.userToken,

				userName: userName,
				userNameFirst: userNameFirst,
				userNameLast: userNameLast,
				userDOB: userDOB,
				userCURP: userCURP,

				userCountry: userCountry,
				userState: userState,
				userCity: userCity,
				userCP: userCP,
				userLocation: userLocation,
				userPhone: userPhone,

				userEmergencyName: userEmergencyName,
				userEmergencyPhone: userEmergencyPhone,
				userEmergencyRisks: userEmergencyRisks,
			}
			if (userPassword !== '') {
				data.userPassword = userPassword
			}
			if (userEmail !== user.userEmail) {
				data.userEmail = userEmail
			}

			setLoadingBar(true)
			axios
				.put(
					`${platformURL}user`,
					{ data },
					{
						headers: {
							Authorization: `Bearer ${platformBearerToken}`,
							DiaToken: userToken,
						},
					}
				)
				.then((res) => {
					setLoadingBar(false)
					// PUT userObject no regresa userToken, simulando...
					res.data.userToken = userToken
					userLogin(res.data)
					setAlert({
						type: 'success',
						title: 'Actualización exitosa',
						message: 'Gracias por mantener tu información actualizada',
					})
				})
				.catch((err) => {
					console.error(err)
					setLoadingBar(false)
					const error = err.response?.data?.error_txt || 'Error desconocido al actualizar tu información'
					setAlert({
						type: 'error',
						title: 'Error al actualizar tu información',
						message: error,
					})
				})
		}

		return false
	}

	// image
	// image
	const [picture, setPicture] = useState(null)
	const [imgData, setImgData] = useState(null)
	const [imgDataStatus, setImgDataStatus] = useState(null)
	const [imgDataStatusVariant, setImgDataStatusVariant] = useState('info')
	const onChangePicture = (e) => {
		if (e.target.files[0]) {
			setImgDataStatus('Leyendo foto...')
			setPicture(e.target.files[0])
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				setImgData(reader.result)
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}
	const imagePreview = () => {
		if (imgData) {
			return <div className="se-user-photo" style={{ backgroundImage: 'url(' + imgData + ')' }}></div>
		} else {
			if (user.userPhoto) {
				return <div className="se-user-photo" style={{ backgroundImage: 'url(' + user.userPhoto + ')' }}></div>
			} else {
				return (
					<div className="se-user-photo">
						<p>Sin fotografía</p>
					</div>
				)
			}
		}
	}

	useEffect(() => {
		if (!picture) {
			return
		}
		setLoadingBar(true)
		setImgDataStatusVariant('warning')
		setImgDataStatus('Subiendo foto...')

		let formData = new FormData()
		formData.append('file', picture)

		axios
			.post(`${platformURL}user/photo`, formData, {
				headers: { Authorization: `Bearer ${platformBearerToken}`, DiaToken: userToken },
			})
			.then((res) => {
				setLoadingBar(false)
				setImgData(null)
				setImgDataStatusVariant('success')
				setImgDataStatus('Tu foto se ha actualizado correctamente')
				res.data.userToken = userToken
				userLogin(res.data)
			})
			.catch((err) => {
				setLoadingBar(false)
				setImgDataStatusVariant('danger')
				setImgDataStatus('Ocurrió un error al actualizar tu foto. Por favor intenta con otro archivo')
				return
			})
	}, [picture])
	// image
	// image

	return (
		<Form id="se-form-core" className="at-form" onSubmit={onSubmit}>
			{/* Detalles generales */}
			{/* Detalles generales */}
			<section className="se-form-section">
				<Row>
					<Col className="se-form-left" md={1}>
						<AiOutlineIdcard style={{ fontSize: '40px' }} color="#999" />
					</Col>
					<Col md={11}>
						<Row>
							<Col md={12}>
								<div className="se-form-right-header">
									<h4>Información básica</h4>
									<small>Detalles generales sobre tu persona</small>
								</div>
							</Col>
							<Col className="se-form-right" md={6}>
								<Form.Group controlId="formBasicUserName">
									<Form.Label>Nombre(s)</Form.Label>
									<Form.Control
										type="text"
										placeholder="Escribe tu nombre"
										value={userName}
										onChange={(e) => setUserName(e.target.value)}
										className={lastError === 'userName' && 'with-error'}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicUserNameFirst">
									<Form.Label>Apellido Paterno</Form.Label>
									<Form.Control
										type="text"
										placeholder="Especifica tu apellido Paterno"
										value={userNameFirst}
										onChange={(e) => setUserNameFirst(e.target.value)}
										className={lastError === 'userNameFirst' && 'with-error'}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicUserNameLast">
									<Form.Label>Apellido Materno</Form.Label>
									<Form.Control
										type="text"
										placeholder="Especifica tu apellido Materno"
										value={userNameLast}
										onChange={(e) => setUserNameLast(e.target.value)}
										className={lastError === 'userNameLast' && 'with-error'}
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group controlId="formBasicUserDOB">
									<Form.Label>Fecha de Nacimiento</Form.Label>
									<Form.Control
										type="text"
										placeholder="Formato: dd/mm/aaaa"
										value={userDOB}
										onChange={(e) => setUserDOB(e.target.value)}
										className={lastError === 'userDOB' && 'with-error'}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicUserCURP">
									<Form.Label>CURP</Form.Label>
									<Form.Control
										type="text"
										placeholder="Por favor proporciona tu CURP"
										value={userCURP}
										onChange={(e) => setUserCURP(e.target.value)}
										className={lastError === 'userCURP' && 'with-error'}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>
			{/* Dirección */}
			{/* Dirección */}
			<section className="se-form-section">
				<Row>
					<Col className="se-form-left" md={1}>
						<FaMapMarkerAlt style={{ fontSize: '40px' }} color="#999" />
					</Col>
					<Col md={11}>
						<Row>
							<Col md={12}>
								<div className="se-form-right-header">
									<h4>Dirección</h4>
									<small>Recuerda mantener tus datos actualizados</small>
								</div>
							</Col>
							<Col className="se-form-right" md={6}>
								<Form.Group controlId="formBasicUserCountry">
									<Form.Label>País</Form.Label>
									<Form.Control
										as="select"
										placeholder="Selecciona tu País"
										value={userCountry}
										onChange={(e) => setUserCountry(e.target.value)}
										className={lastError === 'userCountry' && 'with-error'}
									>
										{countries.map((country, index) => {
											return (
												<option key={index} value={country.code}>
													{country.name_es}
												</option>
											)
										})}
									</Form.Control>
								</Form.Group>

								<Form.Group controlId="formBasicUserState">
									<Form.Label>Estado</Form.Label>
									{userCountry === 'MX' ? (
										<Form.Control
											as="select"
											placeholder="Selecciona tu Estado"
											value={userState}
											onChange={(e) => setUserState(e.target.value)}
											className={lastError === 'userState' && 'with-error'}
										>
											<option value="0">Selecciona tu Estado...</option>
											{estados.map((estado, index) => {
												return (
													<option key={index} value={estado.name}>
														{estado.name}
													</option>
												)
											})}
										</Form.Control>
									) : (
										<Form.Control
											type="text"
											placeholder="Especifica tu Estado"
											value={userState}
											onChange={(e) => setUserState(e.target.value)}
											className={lastError === 'userState' && 'with-error'}
										/>
									)}
								</Form.Group>
								<Form.Group controlId="formBasicUserCity">
									<Form.Label>Ciudad o Población</Form.Label>
									<Form.Control
										type="text"
										placeholder="Especifica tu Ciudad o Población"
										value={userCity}
										onChange={(e) => setUserCity(e.target.value)}
										className={lastError === 'userCity' && 'with-error'}
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group controlId="formBasicUserCP">
									<Form.Label>Código Postal</Form.Label>
									<Form.Control
										type="text"
										placeholder="Indica aquí tu código Postal"
										value={userCP}
										onChange={(e) => setUserCP(e.target.value)}
										className={lastError === 'userCP' && 'with-error'}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicUserLocation">
									<Form.Label>Localidad / Delegación / Municipio</Form.Label>
									<Form.Control
										type="text"
										placeholder="Especifica"
										value={userLocation}
										onChange={(e) => setUserLocation(e.target.value)}
										className={lastError === 'userLocation' && 'with-error'}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicUserPhone">
									<Form.Label>Teléfono</Form.Label>
									<Form.Control
										type="text"
										placeholder="Casa o Celular"
										value={userPhone}
										onChange={(e) => setUserPhone(e.target.value)}
										className={lastError === 'userPhone' && 'with-error'}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>
			{/* Emergencias */}
			{/* Emergencias */}
			<section className="se-form-section">
				<Row>
					<Col className="se-form-left" md={1}>
						<GiHealthPotion style={{ fontSize: '40px' }} color="#999" />
					</Col>
					<Col md={11}>
						<Row>
							<Col md={12}>
								<div className="se-form-right-header">
									<h4>Contactos de emergencia</h4>
									<small>Padecimientos específicos y contactos de emergencia</small>
								</div>
							</Col>
							<Col className="se-form-right" md={6}>
								<Form.Group controlId="formBasicUserEmergencyName">
									<Form.Label>Nombre de tu contacto de emergencia</Form.Label>
									<Form.Control
										type="text"
										placeholder="Escribe su nombre"
										value={userEmergencyName}
										onChange={(e) => setUserEmergencyName(e.target.value)}
										className={lastError === 'userEmergencyName' && 'with-error'}
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group controlId="formBasicUserEmergencyPhone">
									<Form.Label>Teléfono de tu contacto de emergencia</Form.Label>
									<Form.Control
										type="text"
										placeholder="Casa o Celular"
										value={userEmergencyPhone}
										onChange={(e) => setUserEmergencyPhone(e.target.value)}
										className={lastError === 'userEmergencyPhone' && 'with-error'}
									/>
								</Form.Group>
							</Col>
							<Col md={12}>
								<Form.Group controlId="formBasicUserEmergencyRisks">
									<Form.Label>Enfermedades o padecimientos específicos</Form.Label>
									<Form.Control
										type="text"
										placeholder="Por favor se específico"
										value={userEmergencyRisks}
										onChange={(e) => setUserEmergencyRisks(e.target.value)}
										className={lastError === 'userEmergencyRisks' && 'with-error'}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				</Row>
			</section>
			{/* Fotografía */}
			{/* Fotografía */}
			<section className="se-form-section se-form-section-image">
				<Row>
					<Col className="se-form-left" md={1}>
						<MdPhotoCamera style={{ fontSize: '40px' }} color="#999" />
					</Col>
					<Col md={11}>
						<Row>
							<Col md={12}>
								<div className="se-form-right-header pb-4">
									<h4>Fotografía</h4>
									<small>Adjunta aquí tu fotografía</small>
								</div>
							</Col>
							<Col className="se-form-right se-form-image text-center" md={6}>
								{imagePreview()}
							</Col>
							<Col className="se-form-right" md={6}>
								<Form.Group controlId="formFile" className="mb-3">
									<Form.Label>Selecciona aquí tu archivo a subir</Form.Label>
									<br />
									<Form.Control type="file" onChange={onChangePicture} />
								</Form.Group>
								{imgDataStatus && (
									<Alert variant={imgDataStatusVariant} className="text-center">
										{imgDataStatus}
									</Alert>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</section>
			{/* Acceso */}
			{/* Acceso */}
			<section className="se-form-section se-form-section-acceso">
				<Row>
					<Col className="se-form-left" md={1}>
						<GoKey style={{ fontSize: '40px' }} color="#999" />
					</Col>
					<Col md={11}>
						<Row>
							<Col md={12}>
								<div className="se-form-right-header">
									<h4>Acceso a la Plataforma</h4>
									<small>Administra aquí las claves de acceso para entrar a nuestras plataformas</small>
								</div>
							</Col>
							<Col className="se-form-right" md={6}>
								<Form.Group controlId="formBasicUserEmail">
									<Form.Label>Correo electrónico</Form.Label>
									<Form.Control
										type="text"
										placeholder="Escribe tu correo electrónico"
										value={userEmail}
										onChange={(e) => setUserEmail(e.target.value)}
										className={lastError === 'userEmail' && 'with-error'}
									/>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group controlId="formBasicUserPassword">
									<Form.Label>Contraseña</Form.Label>
									<Form.Control
										type="password"
										placeholder="Escribe tu nueva contraseña"
										value={userPassword}
										onChange={(e) => setUserPassword(e.target.value)}
										className={lastError === 'userPassword' && 'with-error'}
									/>
								</Form.Group>
								<Form.Group controlId="formBasicUserPasswordConfirm">
									<Form.Label>Confirma tu Contraseña</Form.Label>
									<Form.Control
										type="password"
										placeholder="Confirma tu nueva contraseña"
										value={userPasswordConfirm}
										onChange={(e) => setUserPasswordConfirm(e.target.value)}
										className={lastError === 'userPasswordConfirm' && 'with-error'}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
					<Col md={12} className="text-center p-5">
						<Button size="lg" variant="success" type="submit" form="se-form-core">
							<BsCheckCircle
								style={{
									position: 'relative',
									bottom: '1px',
									marginRight: '5px',
								}}
							/>
							Guardar información
						</Button>
					</Col>
				</Row>
			</section>
		</Form>
	)
}
