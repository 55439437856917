import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Form from 'react-bootstrap/esm/Form'
import { useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { BiUserPlus } from 'react-icons/bi'
import { useToasts } from 'react-toast-notifications'
import { useContext } from 'react'
import { UserContext } from '../../context/shared/UserContext'
import axios from 'axios'
import { platformBearerToken, platformURL } from '../../classes/Environment'

export default function SeSignup({ setLoadingBar, setAlert }) {
	const [userEmail, setUserEmail] = useState('')
	const [userPassword, setUserPassword] = useState('')
	const [userPasswordConfirm, setUserPasswordConfirm] = useState('')
	const [userName, setUserName] = useState('')
	const [userNameFirst, setUserNameFirst] = useState('')
	const [userNameLast, setUserNameLast] = useState('')

	// const [userEmail, setUserEmail] = useState('test@institutodia.mx')
	// const [userPassword, setUserPassword] = useState('test')
	// const [userPasswordConfirm, setUserPasswordConfirm] = useState('test')
	// const [userName, setUserName] = useState('Test')
	// const [userNameFirst, setUserNameFirst] = useState('Test')
	// const [userNameLast, setUserNameLast] = useState('Test')

	const [lastError, setLastError] = useState(false)
	const { addToast } = useToasts()

	// Context
	// Context
	const { userLogin } = useContext(UserContext)
	const handleUserSignup = (data, callback) => {
		// req
		setLoadingBar(true)
		axios
			.post(
				`${platformURL}user`,
				{ data },
				{
					headers: { Authorization: `Bearer ${platformBearerToken}` },
				}
			)
			.then((res) => {
				setLoadingBar(false)
				userLogin(res.data)
				callback()
			})
			.catch((err) => {
				setLoadingBar(false)
				const error = err.response?.data?.error_txt || 'Error desconocido al registrarte'
				console.error(error)
				setAlert({
					type: 'error',
					title: 'Error al registrarte',
					message: error,
				})
			})
		// req

		return true
	}
	// Context
	// Context

	// Validation
	// Validation
	const onValidation = () => {
		if (userEmail === '') {
			addToast('Por favor especifica tu correo electrónico', {
				appearance: 'warning',
			})
			setLastError('userEmail')
			return
		}
		if (userPassword === '') {
			addToast('Por favor especifica tu contraseña', {
				appearance: 'warning',
			})
			setLastError('userPassword')
			return
		}
		if (userPasswordConfirm === '') {
			addToast('Por favor confirma tu contraseña', {
				appearance: 'warning',
			})
			setLastError('userPasswordConfirm')
			return
		}
		if (userPasswordConfirm !== userPassword) {
			addToast('Por favor confirma tu contraseña correctamente', {
				appearance: 'warning',
			})
			setLastError('userPasswordConfirm')
			return
		}
		if (userName === '') {
			addToast('Por favor introduce tu(s) nombre(s)', {
				appearance: 'warning',
			})
			setLastError('userName')
			return
		}
		if (userNameFirst === '') {
			addToast('Por favor introduce tu apellido Paterno', {
				appearance: 'warning',
			})
			setLastError('userNameFirst')
			return
		}
		if (userNameLast === '') {
			addToast('Por favor introduce tu apellido Materno', {
				appearance: 'warning',
			})
			setLastError('userNameLast')
			return
		}
		return true
	}

	const onSubmit = (e) => {
		e.preventDefault()
		if (onValidation()) {
			handleUserSignup(
				{
					userEmail: userEmail,
					userPassword: userPassword,
					userName: userName,
					userNameFirst: userNameFirst,
					userNameLast: userNameLast,
				},
				() => {
					setAlert({
						type: 'success',
						title: 'Cuenta creada',
						message: userName + ', tu cuenta ha sido creada exitosamente.',
					})
				}
			)
		}

		return false
	}
	return (
		<Row>
			<Col md={{ span: 10, offset: 1 }} className="text-center">
				<Row>
					<Col md={12}>
						<h4>Crea una cuenta</h4>
						<small>Si aún no tienes una cuenta, puedes crearla aquí mismo</small>
					</Col>
				</Row>

				<Row>
					<Col md={12} className="pt-3">
						<Form id="se-form-signup" className="se-form at-form" onSubmit={onSubmit}>
							<Form.Group controlId="formBasicUserEmail">
								<Form.Control
									type="text"
									placeholder="Introduce aquí tu correo electrónico"
									value={userEmail}
									onChange={(e) => setUserEmail(e.target.value)}
									className={lastError === 'userEmail' && 'with-error'}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicUserPassword">
								<Form.Control
									type="password"
									placeholder="Introduce aquí tu contraseña"
									value={userPassword}
									onChange={(e) => setUserPassword(e.target.value)}
									className={lastError === 'userPassword' && 'with-error'}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicUserPasswordConfirm">
								<Form.Control
									type="password"
									placeholder="Confirma aquí tu contraseña"
									value={userPasswordConfirm}
									onChange={(e) => setUserPasswordConfirm(e.target.value)}
									className={lastError === 'userPasswordConfirm' && 'with-error'}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicUserName">
								<Form.Control
									type="text"
									placeholder="Nombre(s)"
									value={userName}
									onChange={(e) => setUserName(e.target.value)}
									className={lastError === 'userName' && 'with-error'}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicUserNameFirst">
								<Form.Control
									type="text"
									placeholder="Apellido Paterno"
									value={userNameFirst}
									onChange={(e) => setUserNameFirst(e.target.value)}
									className={lastError === 'userNameFirst' && 'with-error'}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicUserNameLast">
								<Form.Control
									type="text"
									placeholder="Apellido Materno"
									value={userNameLast}
									onChange={(e) => setUserNameLast(e.target.value)}
									className={lastError === 'userNameLast' && 'with-error'}
								/>
							</Form.Group>
							<Button size="md" variant="success" type="submit" form="se-form-signup">
								<BiUserPlus
									style={{
										position: 'relative',
										bottom: '1px',
										marginRight: '5px',
									}}
								/>
								Crear cuenta
							</Button>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
