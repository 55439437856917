import React from 'react'
import Badge from 'react-bootstrap/esm/Badge'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { GradeTable } from './GradeTable'
export function Grade({ index, materia }) {
	return (
		<Row key={index} className="pt-3">
			<Col
				md={{
					span: 10,
					offset: 1,
				}}
			>
				<div className="text-center">
					<h5>{materia.name}</h5>
					<p>{materia.description}</p>
				</div>
				<GradeTable materia={materia} />
			</Col>
		</Row>
	)
}
