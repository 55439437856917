import '../css/se-menu.css'

import { Link, useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import { modules } from '../classes/modules'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'

export default function SeMenu() {
	const { anchors, currentAnchor } = useContext(AnchorsContext)
	const location = useLocation()
	const history = useHistory()
	const hexToRgbA = function (hex, alpha) {
		alpha = alpha || '1'
		var c
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('')
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			c = '0x' + c.join('')
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')'
		}
		throw new Error('Bad Hex')
	}

	useEffect(() => {
		const currentModule = modules.find((module) => {
			return module.route === location.pathname
		})

		if (!anchors[currentAnchor]) return

		if (currentModule.id === 'courses' && !anchors[currentAnchor]?._meta.hasCourses) {
			history.push('/')
		}
		if (currentModule.id === 'calendar' && !anchors[currentAnchor]?._meta.hasCalendario) {
			history.push('/')
		}
		if (currentModule.id === 'certificates' && !anchors[currentAnchor]?._meta.hasCertificates) {
			history.push('/')
		}
		if (currentModule.id === 'payments' && !anchors[currentAnchor]?._meta.hasPayments) {
			history.push('/')
		}
		if (currentModule.id === 'rubrica' && !anchors[currentAnchor]?._meta.hasRubricas) {
			history.push('/')
		}
		if (currentModule.id === 'tramites' && !anchors[currentAnchor]?._meta.hasTramites) {
			history.push('/')
		}
		if (currentModule.id === 'grabaciones' && !anchors[currentAnchor]?._meta.hasRecordings) {
			history.push('/')
		}
		if (currentModule.id === 'estructura' && !anchors[currentAnchor]?._meta.hasEstructura) {
			history.push('/')
		}
		if (currentModule.id === 'contacto') {
			// history.push('/')
		}
	}, [currentAnchor])

	return (
		<ul className="se-menu-elements">
			{modules.map((module, index) => {
				if (!anchors[currentAnchor]?._meta.hasCourses && module.id === 'courses') {
					return null
				}
				if (!anchors[currentAnchor]?._meta.hasCalendario && module.id === 'calendar') {
					return null
				}
				if (!anchors[currentAnchor]?._meta.hasCertificates && module.id === 'certificates') {
					return null
				}
				if (!anchors[currentAnchor]?._meta.hasPayments && module.id === 'payments') {
					return null
				}
				if (!anchors[currentAnchor]?._meta.hasRubricas && module.id === 'rubrica') {
					return null
				}
				if (!anchors[currentAnchor]?._meta.hasTramites && module.id === 'tramites') {
					return null
				}
				if (!anchors[currentAnchor]?._meta.hasRecordings && module.id === 'grabaciones') {
					return null
				}
				if (module.id === 'estructura') {
					return null
				}
				if (module.id === 'contacto') {
					return null
				}
				let isActive = location.pathname === module.route || false
				return (
					<Link to={module.route} key={index}>
						<li
							className={isActive ? 'active' : ''}
							style={{
								backgroundColor: isActive ? '#FFF' : hexToRgbA(module.color, '.4'),
								borderColor: isActive && module.color,
							}}
						>
							<p>{module.shortName}</p>
						</li>
					</Link>
				)
			})}
		</ul>
	)
}
