import "./CoreInfoForm.css";

// Bootstrap
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/esm/Form";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";

import { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { platformBearerToken, platformURL } from "../../classes/Environment";
import { useContext } from "react";
import { UserContext } from "../../context/shared/UserContext";

export default function ContactoForm({ setAlert, setLoadingBar }) {
  //   const { user, userToken, userLogin } = useContext(UserContext);

  // ******* Campos usuario *****
  // ******* Campos usuario *****
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Funcionamiento formulario
  const [lastError, setlastError] = useState(false);
  const { addToast } = useToasts();

  const history = useHistory();

  // Validation
  // Validation
  const onValidation = () => {
    if (name === "") {
      addToast("Por favor especifica tu(s) nombre(s)", {
        appearance: "warning",
      });
      setlastError("name");
      return;
    }
    if (email === "") {
      addToast("Por favor especifica tu correo electrónico", {
        appearance: "warning",
      });
      setlastError("email");
      return;
    }
    if (message === "") {
      addToast("Por favor especifica tu mensaje", {
        appearance: "warning",
      });
      setlastError("message");
      return;
    }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setlastError(false);

    // validation
    if (onValidation()) {
      const data = {
        contactName: name,
        contactEmail: email,
        contactMessage:
          "**Información enviada desde el Cliente de Servicios Escolares**" +
          "\n\n" +
          message,
      };
      setLoadingBar(true);
      axios
        .post(
          `${platformURL}webContact`,
          { data },
          {
            headers: {
              Authorization: `Bearer ${platformBearerToken}`,
            },
          }
        )
        .then((res) => {
          setLoadingBar(false);
          setAlert({
            type: "success",
            title: "Envío exitoso",
            message: "Nos pondremos en contacto en cuanto nos sea posible",
          });
          setName("");
          setEmail("");
          setMessage("");
        })
        .catch((err) => {
          console.error(err);
          setLoadingBar(false);
          const error =
            err.response?.data?.error_txt ||
            "Error desconocido al enviar la información";
          setAlert({
            type: "error",
            title: "Error al enviar la información",
            message: error,
          });
        });
    }

    return false;
  };

  return (
    <Form id="se-contact-form" className="at-form" onSubmit={onSubmit}>
      {/* Detalles generales */}
      {/* Detalles generales */}
      <section className="se-form-section">
        <Row>
          <Col md={12}>
            <Form.Group controlId="formBasicUserName" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={lastError === "name" && "with-error"}
                style={{ border: "1px solid #77808B" }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicUserNameFirst" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={lastError === "email" && "with-error"}
                style={{ border: "1px solid #77808B" }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicUserNameLast" className="mb-3">
              <Form.Control
                as="textarea"
                type="text"
                placeholder="Mensaje"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={lastError === "message" && "with-error"}
                style={{ minHeight: "200px", border: "1px solid #77808B" }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <Button
              size="lg"
              variant="success"
              type="submit"
              form="se-contact-form"
              style={{
                width: "100%",
                backgroundColor: "#E80239",
                border: "0px",
              }}
            >
              Enviar
            </Button>
          </Col>
        </Row>
      </section>
    </Form>
  );
}
