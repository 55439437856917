import { FaMoneyCheckAlt, FaRegCalendarAlt, FaRegIdCard } from 'react-icons/fa'
import { GoTasklist } from 'react-icons/go'
import { AiOutlineCheckCircle, AiOutlineFileDone, AiOutlineStar } from 'react-icons/ai'
import { BiVideoRecording } from 'react-icons/bi'
import { IoMdList } from 'react-icons/io'

export const modules = [
	{
		name: 'Perfil',
		shortName: 'Perfil',
		id: 'core-info',
		route: '/informacion-basica',
		icon: <FaRegIdCard size="4.5em" color="#D72947" />,
		color: '#D72947',
		desc: 'Actualiza tu información de contacto',
	},
	{
		name: 'Calendario Escolar',
		shortName: 'Calendario',
		id: 'calendar',
		route: '/calendario',
		icon: <FaRegCalendarAlt size="4.5em" color="#cb843f" />,
		color: '#cb843f',
		desc: 'Consulta tus eventos',
	},
	{
		name: 'Cursos',
		shortName: 'Cursos',
		id: 'courses',
		route: '/cursos',
		icon: <GoTasklist size="4.5em" color="#1b5292" />,
		color: '#1b5292',
		desc: 'Visita tus cursos en línea',
	},
	{
		name: 'Calificaciones',
		shortName: 'Calificaciones',
		id: 'rubrica',
		route: '/calificaciones',
		icon: <AiOutlineCheckCircle size="4.5em" color="#b7dddb" />,
		color: '#b7dddb',
		desc: 'Consulta tus calificaciones',
	},
	{
		name: 'Mis Constancias',
		shortName: 'Constancias',
		id: 'certificates',
		route: '/certificados',
		icon: <AiOutlineStar size="4.5em" color="#ccbcd4" />,
		color: '#ccbcd4',
		desc: 'Descarga tus constancias',
	},
	{
		name: 'Pagos',
		shortName: 'Pagos',
		id: 'payments',
		route: '/pagos',
		icon: <FaMoneyCheckAlt size="4.5em" color="#87c053" />,
		color: '#87c053',
		desc: 'Consulta y Realiza tus pagos',
	},
	{
		name: 'Trámites y Consultas',
		shortName: 'Trámites',
		id: 'tramites',
		route: '/tramites',
		icon: <AiOutlineFileDone size="4.5em" color="#f5e5bb" />,
		color: '#f5e5bb',
		desc: 'Realiza consultas generales',
	},
	{
		name: 'Grabaciones',
		shortName: 'Grabaciones',
		id: 'grabaciones',
		route: '/grabaciones',
		icon: <BiVideoRecording size="4.5em" color="#f5e5bb" />,
		color: '#cb843f',
		desc: 'Consulta grabaciones de sesiones',
	},
	{
		name: 'Estructura del Posgrado',
		shortName: 'Estructura',
		id: 'estructura',
		route: '/estructura',
		icon: <IoMdList size="4.5em" color="#87c053" />,
		color: '#87c053',
		desc: 'Estructura del Posgrado',
	},
	{
		name: 'Contacto',
		shortName: 'Contacto',
		id: 'contacto',
		route: '/contacto',
		icon: <IoMdList size="4.5em" color="#87c053" />,
		color: '#87c053',
		desc: 'Contacto',
	},
]
