import './CalendarPage.css'

import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Modal from 'react-bootstrap/esm/Modal'
import Row from 'react-bootstrap/esm/Row'

import { useHistory } from 'react-router-dom'
import SeMenu from '../components/SeMenu'
import SeHeroUser from '../components/SeHeroUser'
import { useState } from 'react'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import iCalendarPlugin from '@fullcalendar/icalendar'
import Button from 'react-bootstrap/esm/Button'

import { BiCalendarCheck } from 'react-icons/bi'
import Alert from 'react-bootstrap/esm/Alert'
import moment from 'moment'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'
import { UserContext } from '../context/shared/UserContext'
import { modules } from '../classes/modules'

import parse from 'html-react-parser'

export default function CalendarPage() {
	const history = useHistory()
	const { anchors, currentAnchor, setCurrentAnchor, events } = useContext(AnchorsContext)
	const { user } = useContext(UserContext)

	const getFullCalendarEvents = () => {
		if (!events) return []

		let fcEventsAdm = []
		let fcEventsAca = []
		let fcEventsFestive = []

		events.map((event, index) => {
			if (event.endHourText === '0:00') {
				// console.log(`Aplicando este fix para evento ${event.name}`)
				// console.log(`Inicio: ${event.startFC} Final: ${event.endFC}`)
				event.endFC = moment.unix(event.endUnix).add(1, 'days').format('Y-MM-D')
				// console.log(`Inicio: ${event.startFC} Final: ${event.endFC}`)
			}
			if (event.type === 1) {
				fcEventsAca.push({
					title: event.name,
					start: event.startFC,
					end: event.endFC,
					extendedProps: {
						description: event.description,
						startDate: event.startDate,
						endDate: event.endDate,
						startHourText: event.startHourText,
						endHourText: event.endHourText,
					},
				})
			}
			if (event.type === 2) {
				fcEventsAdm.push({
					title: event.name,
					start: event.startFC,
					end: event.endFC,
					extendedProps: {
						description: event.description,
						startDate: event.startDate,
						endDate: event.endDate,
						startHourText: event.startHourText,
						endHourText: event.endHourText,
					},
				})
			}
			if (event.type === 3) {
				fcEventsFestive.push({
					title: event.name,
					start: event.startFC,
					end: event.endFC,
					extendedProps: {
						description: event.description,
						startDate: event.startDate,
						endDate: event.endDate,
						startHourText: event.startHourText,
						endHourText: event.endHourText,
					},
				})
			}
		})

		const returnedValue = [
			{
				events: fcEventsAdm,
				color: '#1b5292',
				textColor: '#FFF',
			},
			{
				events: fcEventsAca,
				color: '#cb843f',
			},
			{
				events: fcEventsFestive,
				color: '#d4d4d4',
				textColor: '#333',
			},
		]

		return returnedValue
	}

	const eventClick = (info, ep) => {
		// console.log(info.title, ep)
		setModal({ title: info.event.title, description: info.event.description })
	}

	// Modal
	// Modal
	const [show, setShow] = useState(false)
	const [modal, setModal] = useState({ title: 'Default', description: 'default' })

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	// Modal
	// Modal

	const hasCalendar = anchors !== false && anchors[currentAnchor]?.calendar && anchors[currentAnchor].calendar.events ? true : false
	return (
		<Container className="se-container-page">
			<SeHeroUser user={user} anchors={anchors} currentAnchor={currentAnchor} setCurrentAnchor={setCurrentAnchor}></SeHeroUser>
			<section className="se-section se-section-core-calendar">
				<SeMenu anchors={anchors} currentAnchor={currentAnchor} modules={modules} />
				<div className="wrapper pt-4">
					{!hasCalendar && (
						<Row className="pt-3">
							<Col md={12} className="text-center">
								<Alert variant="warning">Este periodo aún no cuenta con Calendario. Te sugerimos regresar más tarde</Alert>
							</Col>
						</Row>
					)}
					{hasCalendar && (
						<Row>
							<Col md={12} className="">
								<>
									<Row className="pb-3">
										<Col md={12}>
											<strong>{user.userName}</strong>, este es el calendario escolar asociado a tu plan de estudios
										</Col>
									</Row>
									<FullCalendar
										locale="es"
										plugins={[dayGridPlugin, iCalendarPlugin]}
										initialView="dayGridMonth"
										eventSources={getFullCalendarEvents()}
										eventClick={(info) => {
											// console.log(info.event.extendedProps.startDate, info.event.extendedProps.endDate)
											setModal({
												title: info.event.title,
												startDate: info.event.extendedProps.startDate,
												startHourText: info.event.extendedProps.startHourText,
												endDate: info.event.extendedProps.endDate,
												endHourText: info.event.extendedProps.endHourText,
												description: <div>{parse(info.event.extendedProps.description)}</div>,
											})
											handleShow()
										}}
										// eventSources={[{ url: 'https://tockify.com/api/feeds/ics/mlsupport', format: 'ics' }]}
										// eventSources={[{ url: 'http://pi2legacy.local/calendar/subscribe/absences/', format: 'ics' }]}
										// eventSources={[{ url: 'https://pi2.lavaca.dev/calendar/subscribe/absences/', format: 'ics' }]}
									/>
									<Modal size="lg" show={show} onHide={handleClose}>
										<Modal.Header>
											<Modal.Title>{modal.title}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<h5>
												{modal.startDate === modal.endDate ? (
													<>
														{modal.startDate}
														{modal.endHourText !== '0:00' && (
															<span className="text-hint" style={{ marginLeft: '5px' }}>
																{modal.startHourText !== '0:00' && modal.startHourText} -{' '}
																{modal.endHourText !== '0:00' && modal.endHourText}
															</span>
														)}
													</>
												) : (
													<>
														{modal.startDate} - {modal.endDate}
													</>
												)}
											</h5>
											<p>{modal.description}</p>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" onClick={handleClose}>
												Cerrar
											</Button>
										</Modal.Footer>
									</Modal>
								</>
							</Col>
						</Row>
					)}
					{hasCalendar && (
						<Row className="">
							<Col md={12} className="se-calendar-buttons text-center pt-4">
								<a href={anchors[currentAnchor].calendar._meta.urls.google} target="_blank" rel="noreferrer">
									<Button size="sm" variant="info">
										<BiCalendarCheck /> Suscríbete a este calendario con Google Calendar
									</Button>
								</a>
								<a href={anchors[currentAnchor].calendar._meta.urls.ics} target="_blank" rel="noreferrer">
									<Button size="sm" variant="primary">
										<BiCalendarCheck /> Suscríbete a este calendario con Outlook (Microsoft) o Calendar (Apple)
									</Button>
								</a>
							</Col>
						</Row>
					)}
				</div>
			</section>
		</Container>
	)
}
