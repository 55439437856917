import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Form from 'react-bootstrap/esm/Form'
import Alert from 'react-bootstrap/esm/Alert'
import { useContext } from 'react'
import { AnchorsContext } from '../context/shared/AnchorsContext'

export default function SePeriodSelector() {
	const { anchors, currentAnchor, currentPeriod, setCurrentPeriod } = useContext(AnchorsContext)
	return (
		<>
			{currentPeriod !== false ? (
				<Row>
					<Col md={{ span: 6, offset: 3 }} className="text-center pb-4">
						<Form.Group controlId="formBasicUserCountry">
							<Form.Label>Selecciona un periodo</Form.Label>
							<Form.Control
								className="form-select"
								as="select"
								placeholder="Selecciona un periodo"
								value={currentPeriod}
								onChange={(e) => setCurrentPeriod(e.target.value)}
							>
								{anchors[currentAnchor]?.periods.length > 0 ? (
									anchors[currentAnchor].periods.map((period, index) => (
										<option value={+index} key={index}>
											{period._meta.name}
										</option>
									))
								) : (
									<option value={false}>Este plan de estudios no cuenta con periodos a seleccionar</option>
								)}
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>
			) : (
				<Row className="pt-3">
					<Col md={12} className="text-center">
						<Alert variant="warning">Este plan de estudios aún no cuenta con periodos. Te sugerimos regresar más tarde</Alert>
					</Col>
				</Row>
			)}
		</>
	)
}
